var main = require('@/main')
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.config.globalProperties.$http = main.Axios
app.config.globalProperties.$formatDate = (date, locales_id, time_zone, is_utc = false) => {
    if (is_utc) {
        return main.timezone.tz(main.timezone.utc(date), time_zone).locale(locales_id).format('L LTS')
    }
    return main.timezone(date).format('YYYY-MM-DD HH:mm:ss')
}
app.config.globalProperties.$formatAmount = (locales_id, amount, currency) => {
    amount = ( amount / 100 ).toFixed(2)
    var formatter = new Intl.NumberFormat(locales_id, {
        style: 'currency',
        currency
    })
    return formatter.format(amount)
}
app.config.globalProperties.$formatLabel = (label) => {
    switch (label) {
        case 'AMERICAN EXPRESS':
            label = 'AMEX'
            break;
        case 'MAESTRO':
            label = 'Maestro'
            break;
        case 'MASTERCARD':
            label = 'Mastercard'
            break;
        case 'VPAY':
            label = 'V_Pay'
            break;
        case 'VISA Electron':
            label = 'Visa_Electron'
            break;
        case 'VISA ELECTRON':
            label = 'Visa_Electron'
            break;
        case 'VISA':
            label = 'VISA'
            break;
        case 'VISA Debit/Credit':
            label = 'VISA'
            break;
    }
    return label
}
app.config.globalProperties.$formatTransactionStatus = (status) => {
    switch (status) {
        case 0:
            status = 'Pending'
            break;
        case 1:
            status = 'Charged'
            break;
        case 2:
            status = 'Closed'
            break;
        case 3:
            status = 'ProvisionallyCharged'
            break;
        default:
            status = 'N/A'
            break;
    }
    return status
}
app.config.globalProperties.$formatPaymentStatus = (status) => {
    switch (status) {
        case 1:
            status = 'Initialized'
            break;
        case 2:
            status = 'Authorized'
            break;
        case 3:
            status = 'Remitted'
            break;
        case 4:
            status = 'Refused'
            break;
        case 5:
            status = 'Waiting_for_recovery'
            break;
        case 6:
            status = 'Waiting_for_regularization'
            break;
        case 7:
            status = 'Regularized'
            break;
        case 8:
            status = 'Debt_open'
            break;
        case 9:
            status = 'Debt_cleared'
            break;
        default:
            status = 'N/A'
            break;
    }
    return status
}
app.config.globalProperties.$formatTransactionType = (type) => {
    switch (type) {
        case 1:
            type = 'Debit'
            break;
        default:
            type = 'N/A'
            break;
    }
    return type
}

app.use(main.i18n)
app.use(router)
app.use(main.store)
app.use(main.VCalendar)
app.use(main.VueReCaptcha, {
    siteKey: main.config.recaptcha.site_key
})
app.mount('#app')