<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 text-center mt-4">
            <h5 class="fw-bold font-monospace">
                v{{ version.version }}
            </h5>
        </div>
        <div class="col-12 text-center mt-2">
            <small class="fw-lighter fst-italic">
                Powered by
            </small>
        </div>
        <div class="col-12 text-center mb-2">
            <img src="@/assets/img/ConduentLogo.svg">
        </div>
        <div class="col-12 text-center">
            <small class="col-12 col-sm-9">
                Copyright &copy; {{ year }} Conduent Business Solutions. {{ $t('Version.copyright') }}
            </small>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    name: 'VersionComp',
    computed: {
        ...mapState(['config', 'version'])
    },
    data() {
        return {
            year: moment().year()
        }
    },
}
</script>

<style lang="scss" scoped>

    h5,
    small {
        text-shadow: 0 0 8px white;
    }

    img {
        width: 15%;
    }

    @media screen and (max-width: 992px) {
        img {
            width: 25%;
        }
    }
    @media screen and (max-width: 576px) {
        img {
            width: 40%;
        }
    }
</style>