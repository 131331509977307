import { createI18n } from 'vue-i18n'
import config from '../../config.json'
import en from './translations/en.json'
import it from './translations/it.json'
import fr from './translations/fr.json'
import es from './translations/es.json'

if (localStorage.getItem('session_language') == null) {
    if (config.supported_languages.includes(navigator.language.slice(0,2))) {
        localStorage.setItem('session_language', navigator.language.slice(0,2))
    } else {
        localStorage.setItem('session_language', config.default_language)
    }
}

const i18n = createI18n({
    locale: localStorage.getItem('session_language'),
    messages: {
        en,
        it,
        fr,
        es
    }
})


export default i18n