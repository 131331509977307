<template>
    <div class="d-flex flex-wrap">
        <div class="col-12">
            <h1 class="text-first text-center">
                <i class="fa-solid fa-credit-card me-2"></i>
                {{ $t(`Travels.${this.$route.name}.RouteName`) }}
            </h1>
        </div>
        <div class="col-12 my-5 border border-third rounded-5 p-4 bg-white" v-if="!loader && payments">
            <DataTable :date="selectedDate" :tbody="payments" @filterByDate="filterByDate" @getReceipt="getReceipt"/>
        </div>
    </div>
    <transition name="fade-receipt">
        <Receipt :currentTimeStamp="currentTimeStamp" :receipt="receipt" @download="download" @close="receipt = null" v-if="receipt"/>
    </transition>
    <ReceiptLoader v-if="receiptLoader"/>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import cc from 'currency-codes'
import html2pdf from 'html2pdf.js'
import DataTable from '../../components/DataTables/PaymentsDataTable.vue'
import Receipt from '../../components/Receipt.vue'
import ReceiptLoader from '@/components/ReceiptLoader.vue'
export default {
    name: 'PaymentsView',
    components: {
        DataTable,
        Receipt,
        ReceiptLoader
    },
    computed: {
        ...mapState(['loader', 'credentials', 'receiptLoader']),
        ...mapGetters(['ApiPath'])
    },
    data() {
        return {
            payments: null,
            selectedDate: null,
            currentTimeStamp: null,
            receipt: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setReceiptLoader']),
        async getPayments(startDate, endDate) {
            this.setLoader(true)
            if (!startDate) {
                startDate = moment().subtract(29, 'days').set({hour:0,minute:0,second:0}).format()
            }
            if (!endDate) {
                endDate = moment().set({hour:25,minute:59,second:59}).format()
            }

            const payments = []

            for (var i = 0; i < this.credentials.cardAccounts.length; i++) {
                var transactions = null
                try {
                    transactions = await this.$http.get(`${this.ApiPath}/transactions`, {
                        params: {
                            aggregationId: this.credentials.cardAccounts[i].aggregationId,
                            cardSequence: this.credentials.cardAccounts[i].cardSequence,
                            startDate,
                            endDate
                        }
                    })
                } catch (err) {
                    // console.log(err)
                    transactions = {
                        data: {
                            transactions: []
                        }
                    }
                }
                for (var x = 0; x < transactions.data.transactions.length; x++) {
                    var transaction = transactions.data.transactions[x]
                    var currency = cc.number(transaction.currency)
                    transaction.accountingPeriodStartDate = this.$formatDate(transaction.accountingPeriodStartDate, localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                    transaction.accountingPeriodEndDate = this.$formatDate(transaction.accountingPeriodEndDate, localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                    transaction.chargedAmount = this.$formatAmount(localStorage.getItem('session_language'), transaction.chargedAmount, currency.code)
                    transaction.vatAmount = this.$formatAmount(localStorage.getItem('session_language'), transaction.vatAmount, currency.code)
                    transaction.currency = currency.code
                    transaction.vatRate = (transaction.vatRate / 100).toFixed(2)
                    transaction['formFactor'] = this.credentials.cardAccounts[i].formFactor
                    for (var y = 0; y < transaction.payments.length; y++) {
                        var payment = transaction.payments[y]
                        payment['aggregationId'] = this.credentials.cardAccounts[i].aggregationId,
                        payment['transaction'] = transaction
                        payment.lastTapDate = this.$formatDate(payment.lastTapDate, localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                        payment.chargedAmount = this.$formatAmount(localStorage.getItem('session_language'), payment.chargedAmount, currency.code)
                        payments.push(payment)
                    }
                }
            }
            this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
            this.setLoader(false)
            return payments
        },
        async filterByDate(when) {
            this.payments = null
            this.payments = await this.getPayments(when.startDate, when.endDate)
        },
        async getReceipt(payment) {
            this.setReceiptLoader(true)
            var paymentReceipts = null
            try {
                paymentReceipts = await this.$http.get(`${this.ApiPath}/v2/paymentReceipts`, {
                    params: {
                        paymentId: payment.paymentId
                    }
                })
            } catch (err) {
                // console.log(err)
                paymentReceipts = {
                    data: []
                }
            }
            this.currentTimeStamp = this.$formatDate(moment(), localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
            for (var i = 0; i < paymentReceipts.data.length; i++) {
                var receipt = paymentReceipts.data[i]
                var currency = cc.number(receipt.currency)
                receipt.lastTapDate = this.$formatDate(receipt.lastTapDate, localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                receipt.amount = this.$formatAmount(localStorage.getItem('session_language'), receipt.amount, currency.code) 
                receipt.bankingTransactionType = this.$formatTransactionType(receipt.bankingTransactionType)
                receipt.vatAmount = payment.transaction.vatAmount
                receipt.vatRate = payment.transaction.vatRate
                
            }
            this.setReceiptLoader(false)
            this.receipt = paymentReceipts.data[0]
        },
        download() {
            html2pdf(document.getElementById('receipt'), {
                jsPDF: {
                    format: 'a5'
                },
                margin: 1,
                filename: `${this.$t(`Travels.${this.$route.name}.PaymentReceipt.receiptTitle`)}-${this.currentTimeStamp}.pdf`
            })
        }
    },
    async created() {
        this.payments = await this.getPayments()
    }
}
</script>