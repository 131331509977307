<template>
    <nav class="d-flex justify-content-center align-items-center flex-wrap bg-second border border-third rounded-5 w-100 items-get-in">
        <router-link to="/journeys" class="text-center navbar-link">
            <i class="fa-solid fa-route fs-2"></i>
            <h4>
                {{ $t('Travels.Navbar.Journeys') }}
            </h4>
        </router-link>
        <router-link to="/payments" class="text-center navbar-link">
            <i class="fa-solid fa-credit-card fs-2"></i>
            <h4>
                {{ $t('Travels.Navbar.Payments') }}
            </h4>
        </router-link>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComp'
}
</script>

<style lang="scss" scoped>
</style>