<template>
    <header class="d-flex justify-content-between align-items-center flex-wrap bg-first p-4">
        <div class="col-12 col-xl-2 d-flex justify-content-center align-items-center">
            <a href="https://ampmetropole.fr/" class="w-100">
                <img :src="require(`@/assets/img/tenants/${ApiKey}.svg`)" class="w-100">
            </a>
        </div>
        <div class="col-12 col-xl-5 d-flex justify-content-center" v-if="credentials">
            <Navbar/>
        </div>
        <div class="col-12 col-xl-2 d-flex justify-content-center justify-content-xl-end items-get-in">
            <LanguagesSelect class="select-auth select-auth-second mt-4 mt-xl-0" v-if="!credentials"/>
            <router-link to="/" class="text-center navbar-link bg-second border border-third rounded-5" @click="this.$store.dispatch('logout')" v-else>
                <i class="fa-solid fa-right-from-bracket fs-2"></i>
                <h4>
                    {{ $t('Travels.Navbar.Logout') }}
                </h4>
            </router-link>
        </div> 
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LanguagesSelect from '@/components/LanguagesSelect.vue'
import Navbar from './Navbar.vue'
export default {
    name: 'HeaderComp',
    components: {
        LanguagesSelect,
        Navbar
    },
    computed: {
        ...mapState(['credentials']),
        ...mapGetters(['ApiKey'])
    }
}
</script>

<style lang="scss" scoped>
    header {
        background-image: url('@/assets/img/tenants/ILk_Vj-29lw8lI6XnQ3lq/background.png');
        background-size: 100%;
        background-position-y: 30%;
        background-repeat: no-repeat;
    }
</style>