import { createRouter, createWebHistory } from 'vue-router'
import Inspection from '../views/Inspection.vue'
import Journeys from '../views/Travels/Journeys.vue'
import Payments from '../views/Travels/Payments.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
    {
        path: '/',
        name: 'Inspection',
        component: Inspection,
        meta: {
            auth: false
        }
    },
    {
        path: '/journeys',
        name: 'Journeys',
        component: Journeys,
        meta: {
            auth: true
        }
    },
    {
        path: '/payments',
        name: 'Payments',
        component: Payments,
        meta: {
            auth: true
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if ('auth' in to.meta && to.meta.auth && localStorage.getItem('session_credentials') == null) {
        return next({
            path: '/'
        })
    } else if ('auth' in to.meta && !to.meta.auth && localStorage.getItem('session_credentials')) {
        return next({
            path: '/journeys'
        })
    } else {
        return next()
    }
})

export default router