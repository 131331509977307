import { createStore } from 'vuex'
import crypto from 'crypto-js' 
import config from '../../config.json'
import version from '../../version.json'
import $ from 'jquery'

export default createStore({
    state: {
        version,
        config,
        token: null,
        cardAccounts: null,
        credentials: null,
        loader: false,
        receiptLoader: false,
        IFrame: true,
        errorFromServer: null
    },
    getters: {
        ApiPath(state) {
            /**
             *  Do not return the port suffix here, it won't handle cases like 
             * https://{domain}:{port}/{URI}
             * will end up like 
             * https://{domain}:{port}/{URI}:{port}
             * 
             * ==> Include the port into the API URL
             * */
            // return `${state.config.api.url}:${state.config.api.port}`
            return `${state.config.api.url}`
        },
        ApiKey(state) {
            return state.config.api.key
        }
    },
    mutations: {
        setCredentials(state, credentials) {
            state.credentials = credentials

            if (credentials) {
                localStorage.setItem('session_credentials', crypto.AES.encrypt(JSON.stringify(credentials), '147258369').toString())
            }

            var session_timeout = setTimeout(() => {
                state.credentials = null
                localStorage.removeItem('session_credentials')
                location.reload()
            }, state.config.session_expired_timeout)
            
            $('#app').mousemove(() => {
                clearTimeout(session_timeout)
                session_timeout = setTimeout(() => {
                    state.credentials = null
                    localStorage.removeItem('session_credentials')
                    location.reload()
                }, state.config.session_expired_timeout)
            })
        },
        setLoader(state, loader) {
            state.loader = loader
        },
        setReceiptLoader(state, receiptLoader) {
            state.receiptLoader = receiptLoader
        },
        setIFrame(state, IFrame) {
            state.IFrame = IFrame
        },
        setErrorFromServer(state, error) {
            state.errorFromServer = error
        }
    },
    actions: {
        autoSetCredentials (context) {
            let session_credentials = localStorage.getItem('session_credentials')
            if (session_credentials) {
                session_credentials = crypto.AES.decrypt(session_credentials, '147258369').toString(crypto.enc.Utf8)
                context.commit('setCredentials', JSON.parse(session_credentials))
            }
        },
        logout (context) {
            context.commit('setCredentials', null)
            localStorage.removeItem('session_credentials')
        }
    },
    modules: {}
})
