<template>
    <div class="dataTables_date text-center position-relative dropdown-center my-4 my-lg-0">
        <label data-bs-toggle="dropdown">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-calendar-days"></i>
                <input class="text-center" :value="date" readonly>
            </div>
        </label>
        <ul class="dropdown-menu dropdowns">
            <li @click="filterByDate('last30Days')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.last30Days') }}
                </span>
            </li>
            <li @click="filterByDate('thisMonth')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.thisMonth') }}
                </span>
            </li>
            <li @click="filterByDate('lastMonth')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.lastMonth') }}
                </span>
            </li>
            <li @click="filterByDate(`last${config.months_period_limit}Months`)">
                <span class="dropdown-item">
                    {{ $t(`DataTable.date_filter.last${config.months_period_limit}Months`) }}
                </span>
            </li>
            <li @click="filterByDate('custom')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.custom') }}
                </span>
            </li>
        </ul>
        <v-date-picker class="calendar position-absolute top-100 d-none" v-model.range="range" :attributes="attrs" trim-weeks :min-date="attrs[1].dates" :max-date="attrs[0].dates" :locale="this.$i18n.locale" @dayclick="setRange"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'
export default {
    name: 'DateRanges',
    computed: {
        ...mapState(['config'])
    },
    data() {
        return {
            range: {
                start: null,
                end: null,
            },
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: moment().format(),
                },
                {
                    dates: null,
                },
                {
                    highlight: {
                        color: 'blue',
                        fillMode: 'light',
                    },
                    dates: '',
                },
            ],
        }
    },
    props: {
        date: String,
    },
    methods: {
        setRange() {
            if (this.range.start && this.range.end) {
                this.$emit('filterByDate', {
                    startDate: moment(this.range.start).set({hour:0,minute:0,second:0}).format(),
                    endDate: moment(this.range.end).set({hour:23,minute:59,second:59}).format()
                })
            }
        },
        filterByDate(when) {
            switch (when) {
                case 'last30Days':
                    this.$emit('filterByDate', {
                        startDate: moment().subtract(29, 'days').set({hour:0,minute:0,second:0}).format(),
                        endDate: moment().set({hour:23,minute:59,second:59}).format()
                    })
                    break;
                case 'thisMonth':
                    this.$emit('filterByDate', {
                        startDate: moment().startOf('month').set({hour:0,minute:0,second:0}).format(),
                        endDate: moment().endOf('month').set({hour:23,minute:59,second:59}).format()
                    })
                    break;
                case 'lastMonth':
                    this.$emit('filterByDate', {
                        startDate: moment().subtract(1, 'months').startOf('month').set({hour:0,minute:0,second:0}).format(),
                        endDate: moment().subtract(1, 'months').endOf('month').set({hour:23,minute:59,second:59}).format()
                    })
                    break;
                case `last${this.config.months_period_limit}Months`:
                    this.$emit('filterByDate', {
                        startDate: moment().subtract(this.config.months_period_limit, 'months').set({hour:0,minute:0,second:0}).format(),
                        endDate: moment().set({hour:23,minute:59,second:59}).format()
                    })
                    break;
                case 'custom':
                    $('.calendar').removeClass('d-none')
                    break;
                default:
                    this.$emit('filterByDate', {
                        startDate: when.startDate,
                        endDate: when.endDate
                    })
                    break;
            }
        }
    },
    created() {
        this.attrs[1].dates = moment().subtract(this.config.months_period_limit, 'months').set({hour:0,minute:0,second:0}).format()
    }
}
</script>

<style lang="scss" scoped>
</style>