<template>
    <div class="loader-backdrop d-flex justify-content-center align-items-center">
        <i class="fa-solid fa-spinner fs-1 receiptLoader"></i>
    </div>
</template>

<script>
export default {
    name: 'ReceiptLoader'
}
</script>

<style lang="scss" scoped>
    
</style>