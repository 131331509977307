<template>
    <iframe :src="iFrameURL" width="100%" height="100%"></iframe>
</template>

<script>
import { mapState } from 'vuex'
const { v4: uuidv4 } = require('uuid') 
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
export default {
    name: 'IFrame',
    computed: {
        ...mapState(['config'])
    },
    data() {
        return {
            iFrameURL: null
        }
    },
    methods: {
        getIFrameUrl() {
            var transaction_id = uuidv4()
            
            var keyMAC = Base64.parse(this.config.tokenizer.keyMac)

            var objectReq = {
                "shop_id": this.config.tokenizer.shop_id,
                "transaction_id": transaction_id,
                "language": localStorage.getItem('session_language')
            }
            var req = window.btoa(JSON.stringify(objectReq))
            var hash = hmacSHA256(req, keyMAC)
            var mac = Base64.stringify(hash)
            this.iFrameURL = `${this.config.tokenizer.iFrameUrl}:${this.config.tokenizer.iFramePort}/tokenize/req/${encodeURIComponent(req)}/mac/${encodeURIComponent(mac)}`

            window.addEventListener('message', (event) => {
                if (event.origin === `${this.config.tokenizer.iFrameUrl}:${this.config.tokenizer.iFramePort}`) {
                    var decoded_res = JSON.parse(window.atob(event.data.res))
                    this.$emit('decoded_res', decoded_res)
                }
            }, false)
        }
    },
    created() {
        this.getIFrameUrl()
    }
}
</script>

<style lang="scss" scoped>
    iframe {
        background-image: url('../assets/img/load.gif');
        background-size: 20%;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>