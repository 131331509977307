<template>
    <span data-bs-toggle="dropdown" class="text-uppercase">
        {{ $t(this.$i18n.locale) }}
        <i class="fa-solid fa-chevron-down ms-2"></i>
        <ul class="dropdown-menu dropdowns">
            <li v-for="language in config.supported_languages" :key="language" @click="setLanguages(language)">
                <span class="dropdown-item text-center" :class="{'active' : language == this.$i18n.locale }">
                    {{ $t(language) }}
                </span>
            </li>
        </ul>
    </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    name: 'LanguagesSelect',
    computed: {
        ...mapState(['config']),
    },
    methods: {
        ...mapMutations(['setIFrame']),
        setLanguages(language) {
            this.$i18n.locale = language
            localStorage.setItem('session_language', language)
            this.setIFrame(false)
            setTimeout(() => {
                this.setIFrame(true)
            }, 500)
        }
    }
}
</script>

<style lang="scss" scoped>
    .show {
        i::before {
            content: "\f077";
        }
    }
</style>