<template>
    <DateRanges @filterByDate="$emit('filterByDate', $event)" :date="date" />
    <table id="datatable" class="table table-striped my-2 dataTable w-100">
        <thead>
            <tr>
                <th class="no-sort"></th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.receipt') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-hashtag me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.aggregationId') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-clock me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.accountingPeriod') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.transactionId') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-chart-simple me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.transactionStatus') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-route me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.journeysNumber') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.transactionChargedAmount') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.vatAmount') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.currency') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-money-bill-trend-up me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.vatRate') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.refOnTransaction') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-clock me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.lastTapDate') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-credit-card me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.paymentStatus') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.paymentChargedAmount') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.paymentId') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.indChargebackDone') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-wallet me-2"></i>
                    {{ $t('DataTable.Travels.Payments.labels.formFactor') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="tr in tbody" :key="tr.id">
                <td class="dtr-control text-center align-middle">
                    <i class="fa-solid fa-circle-plus"></i>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="btn btn-auth btn-pulse" v-if="tr.paymentStatus == 3 && !tr.indChargebackDone" @click="$emit('getReceipt', tr)">
                        <i class="fa-solid fa-file-pdf"></i>
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.aggregationId }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.accountingPeriodStartDate }}
                        <br>
                        <i class="fa-solid fa-arrow-down"></i>
                        <br>
                        {{ tr.transaction.accountingPeriodEndDate }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.transactionId }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Payments.transactionStatus.${tr.transaction.transactionStatus}`) }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.journeysNumber }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.chargedAmount }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.vatAmount }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.currency }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transaction.vatRate }}
                        <i class="fa-solid fa-percent"></i>
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.refOnTransaction }}
                    </span>
                </td>
                <td class="text-center align-middle">
                    <span class="font-monospace">
                        {{ tr.lastTapDate }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Payments.paymentStatus.${tr.paymentStatus}`) }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.chargedAmount }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ tr.paymentId }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        <i class="fa-solid" :class="tr.indChargebackDone ? 'fa-check text-success': 'fa-xmark text-danger'"></i>
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Payments.formFactor.${tr.transaction.formFactor}`) }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import $ from 'jquery'
import DateRanges from '@/components/DateRanges.vue'
export default {
    name: 'TableComp',
    props: {
        tbody: Array,
        date: String
    },
    emits: ['filterByDate', 'getReceipt'],
    components: { 
        DateRanges 
    },
    created() {
        $(document).ready(() => {
            var paymentsDatatable = $('#datatable').DataTable({
                dom: `<'row g-2 justify-content-center justify-content-lg-between align-items-baseline'
						<'col-sm-12 col-lg-4'l>
						<'col-sm-12 col-lg-4'<"dater">>
						<'col-sm-12 col-lg-4'f>>` +
                    "<'row pt-2'<'col-sm-12'tr>>" +
                    "<'row g-2 align-items-center'<'col-sm-12 col-md-5 text-center text-md-start'i><'col-sm-12 col-md-7'p>>",
                language: {
                    lengthMenu: `${this.$t('DataTable.lengthMenu')} ${this.$t(`DataTable.Travels.${this.$route.name}.RouteName`)}`,
                    search: '<i class="fa-solid fa-magnifying-glass"></i>',
                    searchPlaceholder: `${this.$t('DataTable.search')} ${this.$t(`DataTable.Travels.${this.$route.name}.RouteName`)}`,
                    info: `${this.$t('DataTable.info')} ${this.$t(`DataTable.Travels.${this.$route.name}.elements`)}`,
                    paginate: {
                        previous: '<i class="fa-solid fa-circle-chevron-left"></i>',
                        next: '<i class="fa-solid fa-circle-chevron-right"></i>'
                    },
                    zeroRecords: this.$t('DataTable.zeroRecords'),
                    infoFiltered: this.$t('DataTable.infoFiltered'),
                    infoEmpty: `${this.$t('DataTable.infoEmpty')} ${this.$t(`DataTable.Travels.${this.$route.name}.elements`)}`,
                },
                responsive: {
                    details: {
                        type: 'column',
                        renderer: (api, rowIdx, columns) => {
                            var data = $.map(columns, (col) => {
                                if (col.hidden === false) {
                                    return ''
                                }
                                var row = $('<tr>', {
                                    'data-dt-row': col.rowIndex,
                                    'data-dt-column': col.columnIndex
                                })
                                    .append(
                                        $('<td>', {
                                            class: "py-2"
                                        })
                                            .html(col.title)
                                            .css('font-size', '14px'))
                                    .append(
                                        $('<td>', {
                                            class: `${col.className}, text-center`,
                                        }).html(col.data))

                                return row.get(0).outerHTML
                            }).join('')
                            return data ? $('<table class="w-100 table-sm">').append(data) : false
                        }
                    }
                },
                columnDefs: [{
                    className: 'dtr-control',
                    orderable: false,
                    targets: 0
                }],
                order: [
                   [11, 'ASC'] 
                ]
            })
            $('.dater').append($(".dataTables_date"))
            $('input').removeClass('form-control form-control-sm')
            $('select').removeClass('form-select form-select-sm')

            paymentsDatatable.on('responsive-resize', (e, datatable) => {
                datatable.responsive.rebuild().columns.adjust().responsive.recalc()
            })

            paymentsDatatable.on('responsive-display', (e, api, row, showHide) => {
                var rowOpened = $(api.row(row.index()).node())
                if (showHide) {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-circle-minus"></i>`)
                } else {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-circle-plus"></i>`)
                }
            })
        })
    }
}
</script>

<style lang="scss" scoped>
</style>