<template>
    <div class="loader-backdrop d-flex justify-content-center align-items-center">
        <span class="loader"></span>
    </div>
</template>

<script>
export default {
    name: 'LoaderComp'
}
</script>

<style lang="scss" scoped>

</style>